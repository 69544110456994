.info-line{
    // border: solid thin;
    padding: 10px;
    background-color: rgb(247, 242, 242);
}

.info-label{
    padding-right: 20px;
}

.info-header{
    font-size: 1.7em;
    font-weight: bold;
    color: #ff7100;
    text-align: center;
}

.info-detail-header{
    font-size: 1.3em;
    color: #ff7100;
    font-weight: bold;
}

.info-footer{
    text-align: center;
    width: 100%;
}
.badge-cni{
    
}

.badge-cni span {
    width: 8.4em !important;
    margin: auto;
}

.info-badge{
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

@media (min-width: 600px) {
    .info-om{
        border-left: solid;
    }
  }

@media (max-width: 550px) {
    .info-om{
        padding-top: 40px !important;
    }
}

.l-header{
    position: fixed !important;
}