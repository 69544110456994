.auth-content {
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
  }
  
.auth-content-box {
    width: 100%;
    max-width: 425px;
    padding: 30px;
    background: #fff;
    // border: solid thin;
    border-radius: 22px;
    position: relative;
    z-index: 1;
    margin: auto;
    margin-top: 5%;

    @media screen and (max-width: 745px) {
      margin-top: -35px;
    }

    .auth-content-title {
      color: #000;
      font-size: 32px;
      margin-bottom: 25px;
    }

    .input-group {
      background-color: #f3f3f4;
      border-radius: 7px;
    }

    input {
      border: none;
      background-color: #f3f3f4;
      border-radius: 7px;
      font-weight: normal;

      &:disabled {
        cursor: no-drop;
      }
    }
  }

.sendCode-button{
    color: #ff7900;
    &:hover{
        cursor: pointer;
    }
}
