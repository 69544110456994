.l-container {
  padding: 18px 25px 18px 25px;

  .container-title {
    font-weight: bold;
    font-size: 18px;
    line-height: 34px;
    color: #000;
  }
}
