.custom-button {
  //width: 100%;
  padding: 0 15px;
  height: 40px;
  letter-spacing: 0.5px;
  line-height: 50px;
  background-color: #ff7900;
  color: #ffffff !important;
  font-size: 18px;
  border: none;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  transition: background-color 0.3s ease-in-out;

  &:disabled {
    background-color: rgb(212, 212, 212);
    color: #000;
    cursor: no-drop;
  }

  &.inverted {
    background-color: #000;
    color: #fff;
  }
}
