.info-line{
    // border: solid thin;
    padding: 10px;
    background-color: rgb(247, 242, 242);
    border-radius: 10px;
}

.info-label{
    padding-right: 20px;
}

.info-detail-header{
    font-size: 1.4em;
    color: #ff7100;
    font-weight: bold;
}

.rings-loader{
    margin-left: 40% !important;
    text-align: center;
}

.not-ok{
    border: solid 2px #f02849 !important;
    border-radius: 10px;
}

.ok{
    // border: none;
}